import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const GoogleWorkspace = lazy(() => import('sections/hero/GoogleWorkspace.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const GoogleWPrecios = lazy(() => import('sections/services/GoogleWPrecios.js'));
const BotonGoogleW = lazy(() => import('sections/hero/BotonGoogleW.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const BoxCol2Goo = lazy(() => import('sections/hero/BoxCol2Goo.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));


class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>Google Workspace: Productividad y colaboración en la Nube</title>
          <meta name="description" content="Descubre Google Workspace y potencia la productividad y colaboración en tu empresa. Herramientas integradas en la nube para un trabajo eficiente y colaborativo." />
        </Helmet>
        <Layout>
          <GoogleWorkspace />          
          <GoogleWPrecios />
          <BotonGoogleW />
          <Hola />
          <BoxCol2Goo />          
          <Llamenos />
          <BotonCloud />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`